import React from 'react';
import {IBuybackInfo} from "./Dashboard";

export function BuybackInfo(props: {buybackInfo: IBuybackInfo}) {
    return (
        <div className="shadow overflow-hidden sm:rounded-lg">
            <div className="bg-white dark:bg-gray-800 px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                    Buyback Information
                </h3>
            </div>
            <div className="border-t border-gray-300 dark:border-gray-600">
                <dl>
                    <div className="bg-gray-50 dark:bg-gray-900 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
                            Items we buy
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 dark:text-gray-300 sm:mt-0 sm:col-span-2">
                            {props.buybackInfo.acceptedItems}
                        </dd>
                    </div>
                    <div className="bg-white dark:bg-gray-800 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
                            Where we buy
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 dark:text-gray-300 sm:mt-0 sm:col-span-2">
                            {props.buybackInfo.acceptedLocations}
                        </dd>
                    </div>
                    <div className="bg-gray-50 dark:bg-gray-900 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
                            Who to contract
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 dark:text-gray-300 sm:mt-0 sm:col-span-2">
                            {props.buybackInfo.contractRecipient}
                        </dd>
                    </div>
                    <div className="bg-white dark:bg-gray-800 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
                            Base Rate
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 dark:text-gray-300 sm:mt-0 sm:col-span-2">
                            {props.buybackInfo.buybackRate}% Jita Buy
                        </dd>
                    </div>
                    {
                        props.buybackInfo.subdomain === 'highsec' ?
                            <div className="bg-gray-50 dark:bg-gray-900 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dd className="text-sm col-span-3 dark:text-gray-300">
                                    For more information, help or to find out what we are up to, including our competitions <a className="mt-1 text-sm text-indigo-600 dark:text-indigo-300 sm:mt-0 sm:col-span-2" href={"https://discord.gg/TC3ygpeKvk"}>join us on Discord</a>.
                                </dd>
                            </div>
                            :
                            null
                    }

                </dl>
            </div>
        </div>

    );
}