export function formatCurrency(value: number) {
    const maximumFractionDigits = value > 100 ? 0 : 2;
    return new Intl.NumberFormat('en-EN', { maximumFractionDigits }).format(value) + ' ISK';
}

export function formatQuantity(quantity: number) {
    return new Intl.NumberFormat('en-EN', { maximumFractionDigits: 0 }).format(quantity);
}

export function formatPercentage(a: number, b: number) {
    const percentage = calculatePercentage(a, b);
    if (percentage === 0) {
        return '0%';
    }
    return new Intl.NumberFormat('en-EN', { maximumFractionDigits: 0 }).format(percentage) + '%';
}

export function calculatePercentage(a: number, b: number) {
    if (!a || !b) {
        return 0;
    }
    return Math.ceil((a / b) * 100);
}