import React from "react";
import {AppraisalResultTable} from "./AppraisalResultTable";
import {AppraisalResultSummary} from "./AppraisalResultSummary";
import {IAppraisal} from "./IAppraisal";

export interface AppraisalResultProps {
    appraisal: IAppraisal;
    buybackRate: string;
}

export function AppraisalResult(props: AppraisalResultProps) {
    const hasZeros = props.appraisal.items.findIndex((item) => item.buybackPrice === 0) >= 0;
    return (
        <div className="shadow overflow-hidden sm:rounded-lg bg-white dark:bg-gray-700 bg-opacity-90">
            <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 dark:border-gray-700 sm:rounded-lg">
                            <AppraisalResultSummary total={props.appraisal.total} hasZeros={hasZeros} rejectionCodes={props.appraisal.rejectionCodes} appraisalFailures={props.appraisal.appraisalFailures} />
                            <AppraisalResultTable items={props.appraisal.items} buybackRate={props.buybackRate} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}