import React, {useState} from "react";
import {calculatePercentage, formatCurrency, formatPercentage, formatQuantity} from "../util";
import {Item, RateExplanation} from "./RateExplanation";

export function AppraisalResultTable(props: { items: any[], buybackRate: string }) {

    const [modalItem, setModalItem] = useState({} as Item);
    const [enableModal, setEnableModal] = useState(false);

    function showModal(item: any) {
        setModalItem({
            typeName: item.typeName,
            jitaBuy: item.jitaBuy,
            buybackPrice: item.buybackPrice ?? item.buybackPricePerUnit,
            reasons: item.pricingReasons,
        });
        setEnableModal(true);
    }

    return (
        <>
            {enableModal &&
            <RateExplanation close={() => setEnableModal(false)} item={modalItem} buybackRate={props.buybackRate}/>}
            <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-500 table-auto">
                <thead className="bg-gray-50 dark:bg-gray-900">
                <tr>
                    <th scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                        Name
                    </th>
                    <th scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                        Quantity
                    </th>
                    <th scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                        Buyback Price
                    </th>
                    <th scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                        Effective Rate
                    </th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-300 dark:divide-gray-500 dark:bg-gray-800">
                {
                    props.items.sort((a: any, b: any) => b.buybackPriceForStack - a.buybackPriceForStack).map((item: any) => {
                        const effectiveRate = item.pricingReasons?.length > 0 ? calculatePercentage(item.buybackPricePerUnit, item.jitaBuy) : +props.buybackRate;
                        let rowColor = 'text-gray-900 dark:text-gray-300';
                        if (effectiveRate <= 30) {
                            rowColor = 'bg-red-300 text-gray-900';
                        } else if (effectiveRate <= 60) {
                            rowColor = 'bg-yellow-200 text-gray-900';
                        }
                        const formattedEffectiveRate = item.pricingReasons?.length > 0 ? formatPercentage(item.buybackPricePerUnit, item.jitaBuy) : (props.buybackRate + '%');
                        return (
                            <>
                                <tr key={item.typeName + item.buybackPriceForStack} className={rowColor}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="flex items-center">
                                            <div className="flex-shrink-0 h-10 w-10">
                                                <img className="h-10 w-10 rounded-full"
                                                     src={`https://images.evetech.net/types/${item.typeId}/icon?size=64`}
                                                     onError={({ currentTarget }) => {
                                                         currentTarget.onerror = null; // prevents looping
                                                         currentTarget.src="https://highsec.evebuyback.com/logo_transparent.png";
                                                         currentTarget.alt="No icon available for this item"
                                                     }}
                                                     alt={`Ingame icon for ${item.typeName}`}/>
                                            </div>
                                            <div className="ml-4">
                                                <div className="text-sm font-medium">
                                                    {item.typeName.length > 30 ? item.typeName.slice(0, 30) + '...' : item.typeName}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div
                                            className="text-sm">{formatQuantity(item.quantity)}</div>
                                    </td>

                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div
                                            className="text-sm">{formatCurrency(item.buybackPriceForStack)}</div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className="text-sm">{formattedEffectiveRate} Jita Buy</span>
                                        {
                                            item.pricingReasons?.length > 0 &&
                                            <button onClick={() => showModal(item)}
                                                    className="text-sm text-indigo-600 dark:text-indigo-300 hover:text-indigo-900 dark:hover:text-indigo-100">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5"
                                                     viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd"
                                                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                                          clip-rule="evenodd"/>
                                                </svg>
                                            </button>
                                        }
                                    </td>
                                </tr>
                            </>
                        )
                    })
                }
                </tbody>
            </table>
        </>
    )
}