import React from "react";
import {formatCurrency} from "../util";
import copy from "copy-to-clipboard";


export function AppraisalResultSummary(props: { total: number, hasZeros: boolean, rejectionCodes: string[], appraisalFailures?: string }) {
    return (
        <>
            <div className="lg:flex lg:items-center lg:justify-between px-4 py-5 dark:bg-gray-800">
                <div className="flex-1 min-w-0">
                    <h2 className="text-2xl font-bold leading-7 text-gray-500">
                        You get <span className="text-gray-900 dark:text-gray-300">{formatCurrency(props.total)}</span>!
                    </h2>
                </div>
                <div className="mt-5 flex lg:mt-0 lg:ml-4">
                <span className="sm:ml-3">
                  <button type="button" onClick={() => copy(props.total.toFixed(2))}
                          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white dark:text-gray-800 bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-indigo-300 dark:hover:bg-indigo-200">
                        <svg className="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"/>
                        </svg>
                    Copy Price
                  </button>
                </span>
                </div>
            </div>
            {props.appraisalFailures &&
            <div className="px-4 py-5">
                <p className="text-red-700 dark:text-red-200">Please note: The following input could not be parsed: "{ props.appraisalFailures }"</p>
                <p className="text-red-700 dark:text-red-200">This may happen when items are very new, or there are typos in the input.</p>
            </div>
            }
            {props.hasZeros &&
                <div className="lg:flex lg:items-center lg:justify-between px-4 py-5">
                    <p className="text-red-700 dark:text-red-200">Please note: Some items were priced at 0 ISK. Please review the list carefully.</p>
                </div>
            }
            {
                props.rejectionCodes?.length > 0 &&
                <div className="lg:flex lg:items-center lg:justify-between px-4 py-5">
                    <p className="text-red-700 dark:text-red-200">If this contract is on a highsec island, we will reject it. Reasons:
                    {
                        props.rejectionCodes.map((code) => {
                            let reason;
                            switch (code) {
                                case "bad_isk_per_m3":
                                    reason = " Very low ISK/m3, shipping cost far exceeds the value."
                                    break;
                                case "contains_capital":
                                    reason = " Contains ships that are too large (Orca, Freighters) for safe extraction."
                                    break;
                                default:
                                    reason = code;
                                    break;
                            }
                            return reason
                        }).join("")
                    }
                    </p>
                </div>
            }

        </>
    )
}