import React, {useEffect, useState} from "react";
import {IAppraisal} from "./IAppraisal";

export interface AppraisalProps {
    subdomain: string;
    contractRecipient: string;
    onAppraisal: (appraisal: IAppraisal) => void;
    info?: string;
}

const APPRAISAL_API = localStorage.getItem('appraisal-api') ?? `https://5aa6btweli.execute-api.us-east-1.amazonaws.com/dev/appraise` // `https://n0bgi2q3t6.execute-api.us-east-1.amazonaws.com/dev/appraisal_v2`;

export function AppraisalInput(props: AppraisalProps) {

    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [token, setToken] = useState<string>();

    useEffect(() => {
        if (localStorage.getItem('token-v1')) {
            setToken(localStorage.getItem('token-v1')!);
        }
        if (localStorage.getItem('remember-text')) {
            setText(localStorage.getItem('remember-text')!);
            localStorage.removeItem('remember-text');
        }
    }, []);

    async function signin() {
        // remember text, and repopulate when coming back from login
        localStorage.setItem('remember-text', text);
        // send to eve sso
        let clientId;
        switch (props.subdomain) {
            case 'localhost:3000':
            case 'highsec':
                clientId = '7817dc406c90427db9d3570bb3cc495b'
                break;
            case 'lowsec':
                clientId = '542b75e8f71c4fb8aec7d58cdbc1fba5';
                break;
            case 'rhp':
                clientId = '3e8b07185001448ca59312245f12fc4b';
                break;
            default:
                throw Error('Unhandled subdomain. ClientId resolution.');
        }
        window.location.replace(`https://login.eveonline.com/v2/oauth/authorize/?response_type=code&redirect_uri=${encodeURIComponent(`https://${props.subdomain}.evebuyback.com/callback`)}&client_id=${clientId}&state=nah`);
    }

    async function submit() {
        if (loading) {
            return;
        }
        if (text.split('\n').length > 500) {
            alert('The appraisal must not contain more than 500 lines, because contracts in EVE cannot have more than 500 items.')
        }
        setError('');
        setLoading(true);
        props.onAppraisal({} as IAppraisal);
        try {
            const payload: any = {subdomain: props.subdomain, text};
            const referrer = localStorage.getItem('referrer');
            if (referrer) {
                payload.referrer = referrer;
            }
            const headers: any = {
                Authorization: `Bearer ${token}`
            };
            const response = await fetch(APPRAISAL_API, {
                method: 'post',
                body: JSON.stringify(payload),
                headers,
            });
            const data = await response.json();
            if (data.errorCode === 'evepraisal-400') {
                setError('Evepraisal wasn\'t able to parse the input. Please verify it at evepraisal.com.');
            } else if (data.code === 'too_many_items') {
                setError('The appraisal must not contain more than 500 lines, because contracts in EVE cannot have more than 500 items.');
            } else {
                props.onAppraisal(data);
            }
        } catch (e) {
            console.log({e});
            setError('Failed to appraise the items. Please try again in 5 minutes or later.');
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="shadow overflow-hidden sm:rounded-lg">
            <div className="shadow">
                <div className="px-4 py-5 bg-white dark:bg-gray-800 space-y-6">
                    {
                        props.info &&
                        <div className='text-gray-900 text-left px-4 py-2 rounded bg-yellow-300'>
                            {props.info}
                        </div>
                    }
                    <div className="grid grid-cols-4 pl-4 pr-4">
                        <div className="text-gray-900 dark:text-gray-300 text-left">1. Appraise your items</div>
                        <div className="col-span-2 text-gray-900 dark:text-gray-300 text-center">2. Create a contract to {props.contractRecipient}</div>
                        <div className="text-gray-900 dark:text-gray-300 text-right">3. Get paid</div>
                    </div>
                    <div className='text-left pl-4'>
                        { props.subdomain === 'highsec' ? <a href="https://youtu.be/AEVrBXCbRj8" className="text-indigo-600 hover:text-indigo-900 dark:text-indigo-300 dark:hover:text-indigo-100" target="_blank">Let Rushlock show you how to do it.</a> : null}
                    </div>
                </div>
                <div className="px-4
                py-5 bg-white dark:bg-gray-900 bg-opacity-90 space-y-6 border-t border-gray-300 dark:border-gray-600">
                    <div>
                        <div className="mt-1">
                                <textarea name="about" rows={8} value={text}
                                          onChange={(e) => setText(e.target.value)}
                                          className="text-sm shadow-sm focus:ring-indigo-500 dark:focus:indigo-300 focus:border-indigo-500 dark:focus:border-indigo-300 mt-1 block w-full border-gray-300 dark:border-gray-500 rounded-md dark:bg-gray-800 dark:text-gray-300"
                                          placeholder="Tritanium x10"></textarea>
                        </div>
                        <p className="mt-2 text-sm text-gray-900 dark:text-gray-400">
                            Paste the items from your inventory above.
                        </p>
                    </div>
                </div>
                <div className="px-4 py-3 bg-gray-200 dark:bg-gray-800 bg-opacity-90 text-right border-t border-gray-300 dark:border-gray-600">
                    <span className="mr-2 py-2 px-4 text-red-600 dark:text-red-300">{error}</span>
                    <button onClick={() => setText('')}
                            className="inline-flex justify-center mr-2 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-900 dark:text-gray-200 bg-white dark:bg-gray-600 dark:hover:bg-gray-500">
                        Clear
                    </button>
                    {
                        (token) ?
                    <button type="submit" onClick={submit}
                            className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white dark:text-gray-800 bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-300 dark:hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}>
                        {
                            loading && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        }
                        {loading ? 'Processing' : 'Submit'}
                    </button>
                            :
                            <>
                            <button type="submit" onClick={signin} className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white dark:text-gray-800 bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-300 dark:hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}>
                                Sign in to enable the calculator
                            </button>
                                {/*<br/>*/}
                                <p className={'mt-2 text-sm text-gray-900 dark:text-gray-400'}>We require sign in to protect our server from malicious scripts.</p>
                            </>
                    }
                </div>
            </div>
        </div>
    )
}