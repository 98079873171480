import React from 'react';

export function Navigation(props: {buybackName: string, tagline: string}) {

    function setTheme(theme:string) {
        localStorage.theme = theme;
        window.location.replace('/');
    }

    return (
        <nav className="bg-transparent pt-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    <div className="flex items-center">
                        <div className="flex-shrink-0">
                            {props.buybackName === 'Highsec Buyback' && <img className="h-16 w-16" src="/logo_transparent.png" alt="Logo" />}
                        </div>
                        <div className="ml-5 space-x-4">
                            <h1 className="text-white dark:text-gray-300 text-2xl font-bold">{props.buybackName}</h1>
                        </div>
                        <div className="ml-5">
                            <h1 className="text-white dark:text-gray-300 text-2xl">{props.tagline}</h1>
                        </div>
                    </div>
                    <div className="flex items-right">
                        {localStorage.theme === 'dark' ?
                            <button className="text-white dark:text-gray-300" onClick={() => setTheme('light')}>Go
                                light</button>
                            :
                            <button className="text-white dark:text-gray-700" onClick={() => setTheme('dark')}>Go
                                dark</button>
                        }
                    </div>
                </div>
            </div>
        </nav>
    )
}