import React from "react";
import {formatPercentage} from "../util";

export interface ModalProps {
    item: Item;
    buybackRate: string;
    close: () => void;
}

export interface Item {
    buybackPrice: number;
    jitaBuy: number;
    typeName: string;
    reasons: string[];
}

export function RateExplanation(props: ModalProps) {
    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                onClick={props.close}
            >
                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                        <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex" aria-labelledby="slide-over-heading">
                            <div className="relative w-screen max-w-md">
                                <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                                    <button className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                                        <span className="sr-only">Close panel</span>
                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="h-full flex flex-col py-6 bg-white dark:bg-gray-800 shadow-xl overflow-y-scroll">
                                    <div className="px-4 sm:px-6">
                                        <h2 id="slide-over-heading" className="text-lg font-medium text-gray-900 dark:text-gray-300">
                                            Effective Rate
                                        </h2>
                                    </div>
                                    <div className="mt-6 relative flex-1 px-4 sm:px-6">
                                        <div className="absolute inset-0 px-4 sm:px-6">
                                            <p className="text-gray-500 dark:text-gray-400">
                                                Some items diverge from our base rate of {props.buybackRate}% Jita Buy. T1 Industrials for example are difficult
                                                to haul efficiently.
                                            </p>
                                            <p className="text-gray-900 dark:text-gray-300 mt-4">
                                                Below is a detailed explanation of why we price {props.item.typeName} at {props.item.reasons?.length > 0 ? formatPercentage(props.item.buybackPrice, props.item.jitaBuy) : `${props.buybackRate}%`} Jita Buy.
                                            </p>
                                            <p className="text-gray-900 dark:text-gray-300 mt-4">
                                                <ol className="list-disc">
                                                    {props.item.reasons.map((reason) => <li>{reason}</li>)}
                                                </ol>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
}