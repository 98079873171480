import {AppraisalInput} from "./AppraisalInput";
import {AppraisalResult} from "./AppraisalResult";
import React, {useState} from "react";
import {IAppraisal} from "./IAppraisal";
import {IBuybackInfo} from "../Dashboard";

export function Appraisal(props: {buybackInfo: IBuybackInfo}) {

    // @ts-ignore
    const [appraisal, setAppraisal] = useState(undefined as IAppraisal);

    return (
        <>
            <div>
                <AppraisalInput info={props.buybackInfo.info} subdomain={props.buybackInfo.subdomain}
                            onAppraisal={setAppraisal} contractRecipient={props.buybackInfo.contractRecipient}/>
            </div>
            {
                appraisal?.items?.length && (
                    <div className="py-4">
                        <AppraisalResult appraisal={appraisal} buybackRate={props.buybackInfo.buybackRate}/>
                    </div>
                )
            }
        </>
    );
}