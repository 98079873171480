import React from 'react';
import {Navigation} from "./Navigation";
import {BuybackInfo} from "./BuybackInfo";
import {Benefits} from "./Benefits";
import {Appraisal} from "./appraisal/Appraisal";

export interface IBuybackInfo {
    subdomain: string;
    acceptedItems: string;
    acceptedLocations: string;
    contractRecipient: string;
    buybackName: string;
    buybackRate: string;
    appraisalInfo: string;
    tagline: string;
    info?: string;
}

export function Dashboard(props: {buybackInfo: IBuybackInfo}) {

    const isDark = localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches);

    return (
        <div className={isDark ? 'dark' : ''}>
            <Navigation buybackName={props.buybackInfo.buybackName} tagline={props.buybackInfo.tagline}/>
            <main>
                <div className="max-w-7xl mx-auto py-4 sm:px-6 lg:px-8">
                    <div className="px-4 py-4 sm:px-0">
                        <div className="grid grid-cols-3 gap-4">
                            <div className="col-span-1">
                                <div>
                                    <BuybackInfo buybackInfo={props.buybackInfo}/>
                                </div>
                                {
                                    props.buybackInfo.subdomain === 'highsec' ?
                                        <div className="py-4">
                                            <Benefits/>
                                        </div>
                                        : null
                                }
                            </div>
                            <div className="col-span-2">
                                <div>
                                    <Appraisal buybackInfo={props.buybackInfo}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}