import React from "react";

export function Benefits() {
    return (
        <div className="py-4 bg-white dark:bg-gray-800 bg-opacity-90 shadow overflow-hidden sm:rounded-lg">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="lg:text-center">
                    <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 dark:text-gray-300 sm:text-4xl">
                        THE easiest way to sell in EVE
                    </p>
                    <p className="mt-4 max-w-2xl text-xl text-gray-500 dark:text-gray-400 lg:mx-auto">
                        From sorting to hauling, setting up contracts or finding the best station to sell - it all takes
                        time away, ironically, from earning even more ISK from what you love doing!
                    </p>
                    <p className="mt-4 max-w-2xl text-xl text-gray-500 dark:text-gray-400 lg:mx-auto">
                        Contract to us and whISK away!
                    </p>
                </div>

                <div className="mt-10">
                    <dt className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                        Competitive pricing model
                    </dt>
                    <dd className="mt-2 text-base text-gray-500 dark:text-gray-400">
                        We work very hard to ensure we can offer you the best market price and keeping our rate at 90% or above.
                    </dd>
                </div>
                <div className="mt-5">
                    <dt className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                        No hidden fees
                    </dt>
                    <dd className="mt-2 text-base text-gray-500 dark:text-gray-400">
                        We display the price we buy your items for and the effective rate vs. Jita Buy. What you are quoted is what you will receive.
                    </dd>
                </div>
                <div className="mt-5">
                    <dt className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                        Transfers are instant
                    </dt>
                    <dd className="mt-2 text-base text-gray-500 dark:text-gray-400">
                        We approve most contracts issued to us in under an hour.
                    </dd>
                </div>
                <div className="mt-5">
                    <dt className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                        Convenience
                    </dt>
                    <dd className="mt-2 text-base text-gray-500 dark:text-gray-400">
                        Do more of what you love and leave the rest to us!
                    </dd>
                </div>
            </div>
        </div>

    )
}