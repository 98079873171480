import React, {useState} from 'react';
import './App.css';
import { request } from 'graphql-request';

import {Dashboard} from './components/Dashboard';
import {parse} from "querystring";

export const AUTH_API = `https://uc4v3lk6rh.execute-api.us-east-1.amazonaws.com/dev/auth`;

function App() {

  const [buybackInfo, setBuybackInfo] = useState();

  React.useEffect(() => {
    const path = window.location.pathname.replace("/", "");
    const subdomain = localStorage.getItem('subdomain') ?? window.location.host.split('.')[0];
    // const subdomain = 'highsec';
    if (path === 'callback') {
      const queryParams = parse(window.location.search.replace("?", ""));
      fetch(`${AUTH_API}?code=${queryParams.code}&appId=${subdomain}-buyback`)
          .then((res) => res.json())
          .then((data) => {
            localStorage.setItem('token-v1', data.token);
            window.location.replace('/');
          });
    } else {
      if ((subdomain === 'highsec') && path) {
        if (!localStorage.getItem('referrer')) {
          localStorage.setItem('referrer', path);
        }
      }

      request(
          'https://api-us-east-1.graphcms.com/v2/cke5udyggep5r01xw9ldeb6se/master',
          `
        {
          eveBuyback(where: {subdomain: "${subdomain}"}) {
            subdomain
            acceptedItems
            acceptedLocations
            contractRecipient
            buybackName
            buybackRate
            appraisalInfo
            tagline
            info
          }
        }
      `
      ).then((result) => {
        setBuybackInfo(result.eveBuyback);
        document.title = result.eveBuyback.buybackName;
      })
    }
  }, []);

  return buybackInfo ? <Dashboard buybackInfo={buybackInfo!} /> : <p>Loading ...</p>;
}

export default App;
